@font-face { 
    font-family: 'montserrat-extrabold';
    src: url('./montserrat-extrabold.ttf');
    src: local('montserrat-extrabold'), local('montserrat-extrabold'), url('./montserrat-extrabold.ttf') format('truetype');
 }


#titulo_logotipo h1{    
    font-size: 4rem;
    font-family: montserrat-extrabold;   
    color: white;
    text-shadow: 4px 4px 5px black;
}

#cabecalho{
    background-image: url('./fundo_logotipo.png');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}

#titulo_logotipo :hover{
    text-decoration: none !important;
}

span #e_comercial {
    color: rgb(255,204,41);
    font-size: 5rem;
}

.logo_header{
    margin-right: -15px;
}

.conteudoContainer{
    position: 'relative';
}

#titulo2{
    background-color:skyblue;    
    width: 100%;
    padding-top: 5px;
    text-align: center;
}

#navbarResponsive a{
    color:rgba(0,0,0,.5);
    text-decoration: none;
}

#navbarResponsive a :hover{
    color:rgba(0,0,0,.5);
    text-decoration: none;
}