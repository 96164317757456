.artigo{
    clear: both;/*limpa o fluxo anterior de float*/
}
.artigo h1{
    text-align: center;
}

.artigo h2{
    margin-top: 30px;
}

.retornar{
    margin-left: 0px;
    text-align: left;
}

article img{
    max-width: 80vw; 
    height: auto;   
}

