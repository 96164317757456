center#div-logo img{
    width: 200px;
}

#grupo-form{
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
}

div#componente-login{
    margin-top: 10vh;
    margin-bottom: 200px;
}

@media only screen and (max-width: 600px){
    
    #grupo-form{
        width: 70vw;
    }
}

@media only screen and (max-width: 400px){

    #grupo-form{
        width: 90vw;
    }
}