.coluna{
    position: absolute;
    right: 15%;
    bottom: 50px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px; 
}

a.coluna{
    text-decoration: none;
}

.coluna a:hover{
    text-decoration: none; 
}

.botoesMenuContainer{
    height: 100px;
    background-color: rgba(255,69,0,0.5) ;
    border: none;
    border-radius: 20px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.2;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: aliceblue;
    text-align:center;
    padding: 30px 0;
    cursor: pointer;
}

.botoesMenuContainer:hover{
    background-color: rgba(255,69,0,0.7) ;
    border: none;
}

.espacoLinhas{
    margin-top: 10px;
    margin-bottom: 10px;
}

.botoesMenuContainer:visited{
    background-color: rgba(255,69,0,0.7) ;
    border: none;
}
@media screen and (max-width: 770px), (max-height: 500px){
    .coluna{
        position: static;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .botoesMenuContainer{
        background-color: rgba(255,69,0,0.5);
    }

    .botoesMenuContainer:hover{
        background-color: rgba(255,69,0,0.7) ;
        border: none;
    }
}
/*
@media screen and (max-height: 500px){
    .coluna{
        position: static;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .botoesMenuContainer{
        background-color: rgba(255,69,0,0.7);
    }
}*/

