.links-redes_sociais{
    margin-left: 40px;
    cursor: pointer;
    color: whitesmoke;
}


footer{
    background-color: grey;
    color: whitesmoke;
    text-align: center;
}

hr{
    background-color: whitesmoke;
}

a.links-redes_sociais{
    font-size: 50px;
}
